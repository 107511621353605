<template>
  <div>
    <validation-observer ref="simpleRules">
      <spare-part-orders-item-form :get-cars="getCars" />
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
    </validation-observer>
    <add-car :refresh-data="getCars" />
  </div>
</template>

<script>

import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import SparePartOrdersItemForm from '@/views/Spareparts/Orders/ItemForm.vue'
import AddCar from '@/views/Spareparts/Orders/Search/AddCar.vue'

export default {
  name: 'Edit',
  components: {
    AddCar,
    SparePartOrdersItemForm,
    ValidationObserver,
    SaveButton,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    itemData() {
      return this.$store.getters['sparepartOrders/dataItem']
    },
    saveData() {
      return this.$store.getters['sparepartOrders/dataSaveStatus']
    },
    saveDataLines() {
      return this.$store.getters['sparepartOrderLines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push('/sparepart-orders')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.submitStatus = false
      }
      this.getDataItem()
    },
    saveDataLines(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.getDataItem()
    localize('tr')
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('sparepartOrders/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.getCars(response.id_com_customer)
            this.getCustomer(response.id_com_customer)
          }
        })
    },
    getCustomer(idCustomer) {
      this.$store.dispatch('customers/customerView', idCustomer)
    },
    getCars(idCustomer) {
      this.$store.dispatch('cars/carsList', {
        where: {
          'com_cars.id_com_customer': idCustomer,
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('sparepartOrders/saveData', this.itemData)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      }, {
        position: 'bottom-center',
      })
    },
  },
}
</script>
